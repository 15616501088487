(function () {
    const navbar = document.querySelector('.js-nav');
    const searchBarSimple = document.querySelector('.search-bar-simple');
    const triggerSearch = document.querySelector('.js-search');
    const searchBar = document.querySelector('.js-search-bar input.search-bar-keywords-input');
    const searchContainer = document.querySelector('.navbar-form');
    const clearSearchButton = document.querySelectorAll('.btn-clear-search');
    const publicsiteanchorlinks = document.querySelectorAll('a');

    const searchForm = document.querySelector('.navbar-form form');
    const searchInput = document.querySelector('.navbar-form [title="Search"]');

    if (triggerSearch && searchBar) {
        triggerSearch.addEventListener('click', () => {
            if (searchContainer) {
                let isShown = searchContainer.classList.contains('show')

                if (isShown) {
                    searchContainer.ontransitionend = null;
                    searchContainer.classList.remove('show');
                    triggerSearch.ariaExpanded = "false";
                    searchBar.value = '';
                    return
                }

                searchContainer.classList.add('show')
                triggerSearch.ariaExpanded = "true";
                searchContainer.ontransitionend = function (e) {
                    if (e.propertyName === 'height' && !searchBar.value) {
                        searchBar.focus()
                    }
                }
            }
        });

        searchBar.addEventListener('keyup', (event) => {
            if (event.key.toLowerCase() === 'escape') {
                if (searchContainer) {
                    let isShown = searchContainer.classList.contains('show')

                    if (isShown) {
                        searchContainer.ontransitionend = null;
                        searchContainer.classList.remove('show');
                        triggerSearch.ariaExpanded = "false";
                        searchBar.value = '';
                        triggerSearch.focus()
                        return
                    }
                }
            }
        })

        navbar.addEventListener('keyup', (event) => {
            if (event.key.toLowerCase() === 'tab') {
                if (searchContainer) {
                    let isShown = searchContainer.classList.contains('show')
                    if (isShown) {
                        let searchBarSimpleFocusIn = event.target;
                        if (!searchBarSimple.contains(searchBarSimpleFocusIn)) {
                            searchContainer.classList.remove('show');
                            triggerSearch.focus()
                        }
                    }
                }
            }
        })


        searchBar.addEventListener('keydown', (event) => {
            if (event.shiftKey && event.key.toLowerCase() === 'tab') {
                event.preventDefault();
                triggerSearch.focus();
                searchContainer.classList.remove('show');
            }
        });
    }

    window.addEventListener('click', (event) => {

        if (searchContainer.classList.contains('show') &&
            event.target !== triggerSearch &&
            !triggerSearch.contains(event.target) &&
            event.target !== searchContainer &&
            !searchContainer.contains(event.target)) {
            searchContainer.classList.remove('show');
            event.stopPropagation();
        }
    })

    if (clearSearchButton) {
        clearSearchButton.forEach((button) => {
            button.onclick = (event) => {
                const closestSearch = event.currentTarget.parentElement.parentElement.querySelector('.search-bar-keywords-input');
                searchBar.focus();
                if (closestSearch) {
                    closestSearch.value = '';
                }
            };
        });
    }

    publicsiteanchorlinks.forEach(anchorLink => {

        let isModalReviewBtn = anchorLink.classList.contains('external-link-modal-continue-button');
        let expandableMenuLink = anchorLink.classList.contains('js-trigger-submenu');
        let siteLogoLink = anchorLink.classList.contains('custom-logo');

        if(!isModalReviewBtn &&
            !expandableMenuLink && !siteLogoLink && isExternalLink(anchorLink)) {
            anchorLink.innerHTML += `
					<span class="external-link-lexicon-icon-shortcut">
					<svg className="lexicon-icon lexicon-icon-shortcut">
						<use xlink:href="/o/public-site-theme/images/clay/icons.svg#shortcut"/>
					</svg></span>
				`;
            anchorLink.classList.add('external-link');
        }

        if(!isModalReviewBtn && !expandableMenuLink && !siteLogoLink && isExternalLink(anchorLink)){
            anchorLink.onclick = (event) => {
                event.preventDefault();
                document.querySelector('#external-link-modal-continue-button').setAttribute('href', anchorLink.href);
                toggleExternalLinkModal();
            };
        }

    });

})();

function toggleExternalLinkModal() {
    const modal = document.querySelector('#externaLinkModal');
    if (!modal) return;
    Magellan.Modal.open(modal);
}

function isExternalLink(publicSiteLink) {
    if(publicSiteLink.host !== ''){
        if(publicSiteLink.host !== window.location.host){
            return true;
        }
    }
}